import React, {useEffect, useRef, useCallback, useState} from 'react';
import {useAuth} from './AuthContext';
import {useGlobalProvider} from "./GlobalProvider";
import {rules} from '../utilities/viewerUtils';
import {tagSelectRules} from '../utilities/viewerUtils';
import {viewerLinkModes} from '../utilities/viewerUtils';



const ViewerRight = ({urn}) => {
    const viewerDivRight = useRef(null);
    const viewerRight = useRef(null);
    const {getAccessToken} = useAuth();
    const [initialized, setInitialized] = useState(false);
    const {selectedTagIdViewerRight, setSelectedTagIdViewerLeft} = useGlobalProvider();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const selectedOptionsRef = useRef(selectedOptions);
    const [viewerLoaded, setViewerLoaded] = useState(false);
    const [actions, setActions] = useState({
        link2RightAndAsset: false,
        isolateSelection: false,
        selectionColourRed: false
    });
    const [isProgrammaticIsolation, setIsProgrammaticIsolation] = useState(false);
    const isProgrammaticIsolationRef= useRef(isProgrammaticIsolation);

    const [selectedRule, setSelectedRule] = useState('');
    // make sure the selected rule is current
    const selectedRuleRef = useRef(selectedRule);

    useEffect(() => {
        if (viewerRight.current && (selectedOptionsRef.current.includes('link2RightAndAsset'))&& selectedTagIdViewerRight) {
            console.log('Selected Tag right viewer:', selectedTagIdViewerRight);
            performSearchIsolateAndFit(selectedTagIdViewerRight);
        }
    }, [selectedTagIdViewerRight]);

    // Sync isProgrammaticIsolationRef with isProgrammaticIsolation
    useEffect(() => {
        isProgrammaticIsolationRef.current = isProgrammaticIsolation;
    }, [isProgrammaticIsolation]);


    useEffect(() => {
        selectedOptionsRef.current = selectedOptions;
    }, [selectedOptions]);

    useEffect(() => {
        if (!initialized || !viewerDivRight.current) return;

        const {Autodesk} = window;
        initializeViewer(Autodesk);
        const resizeObserver = new ResizeObserver(() => {
            resizeViewer();
        });

        resizeObserver.observe(viewerDivRight.current);

        return () => {
            if (viewerRight.current) {
                viewerRight.current.finish();
                viewerRight.current = null;
            }
            resizeObserver.disconnect();
        };
    }, [initialized]);

    useEffect(() => {
        if (viewerRight.current && urn) {
            const {Autodesk} = window;
            loadDocument(Autodesk, urn);
        }
    }, [urn, initialized]);



    const initializeViewer = (Autodesk) => {
        const options = {
            env: 'AutodeskProduction',
            getAccessToken: async (onTokenReady) => {
                try {
                    const token = await getAccessToken();
                    const timeInSeconds = 3600;
                    onTokenReady(token, timeInSeconds);
                } catch (error) {
                    console.error('Error fetching access token:', error);
                }
            },
        };

        Autodesk.Viewing.Initializer(options, () => {
            const config = {
                // don't load extensions here to early.
                // extensions: ['Autodesk.Viewing.ZoomWindow'],
            };
            viewerRight.current = new Autodesk.Viewing.GuiViewer3D(viewerDivRight.current, config);
            viewerRight.current.start();
            loadDocument(Autodesk, urn);

            viewerRight.current.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, function (event) {
                if (!isProgrammaticIsolationRef.current && event.dbIdArray.length > 0) {
                    setViewerLoaded(true);
                    // cleaning the string from the MTEXT or ....
                    console.log('object clicked');
                    processDbId(viewerRight.current, event.dbIdArray[0]);
                }
            });
        });
    };

    const performSearchIsolateAndFit = (tagId) => {
        if (!viewerRight.current) return;

        setIsProgrammaticIsolation( true);
        console.log('Performing search isolate and fit isProgrammaticIsolationRef :', !isProgrammaticIsolationRef.current);

        viewerRight.current.search(tagId, (ids) => {

            if (selectedOptions.includes('isolateSelection')) {
                viewerRight.current.isolate(ids);
            }
            // important for the selection colour
            viewerRight.current.select(ids);

            if (ids.length > 0) {
                viewerRight.current.fitToView(ids);
//                 let is3D = viewerRight.current.model.is3d();
//                 let camera = viewerRight.current.getCamera();
//                 let originalFov = camera.fov;
//                 if (!is3D) {
// //                     // this is a 2D model
// //                     // camera.position.z -= 10  // Adjust this value to achieve the desired level of "zoom out"
// //                     // viewer.applyCamera(camera, true);
// //                     //camera.zoom = 2;
// //                     // viewerLeft.current.set2dSelectionColor(new THREE.Color(200,200,0),1); // Blue for 2D selection
// // // TODO play aroung with values
//                     camera.fov *= 0.8;
//                     // viewerRight.current.applyCamera(camera);
//                     viewerRight.current.impl.invalidate(true);
//                 }
//                 if (is3D) {
//                     // this is a 3D model
//                     // camera.position.z *= 2  // Adjust this value to achieve the desired level of "zoom out"
//                     // viewerRight.current.applyCamera(camera, true);
//                     //
//                     // camera.fov = 50;
//                     // viewerRight.current.applyCamera(camera, true);
//                     // // // redraw view
//                     // viewerRight.current.impl.invalidate(true);
//                 }
//                 // viewerRight.current.fitToView(ids)
//                 // // When done, restore original fov
//
//                 camera.fov = originalFov;
//                 viewerRight.current.applyCamera(camera, true);
//                 // Update the viewer again
//                 // viewerRight.current.impl.invalidate(true);

            }
            setIsProgrammaticIsolation(false);


        });
    };

    const handleRuleChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedRule(selectedValue);
        // console.log('Selected rule handleRuleChange :', selectedValue,selectedRule);
    };
    useEffect(() => {
        // console.log('Selected rule updated useeffect:', selectedRule);
        selectedRuleRef.current = selectedRule;
    }, [selectedRule]); // This effect runs every time selectedRule changes


    const processDbId = (viewer, dbId) => {
        viewer.getProperties(dbId, (props) => {
            console.log('Properties of the selected object processDbId viewer right:', props);
            console.log('Rule viewer right:', selectedRule);

            if (selectedOptionsRef.current.includes('isolateSelection')) {
                viewerRight.current.isolate(dbId);
            }

            // Add logging inside the tagSelectRules function to debug its behavior
            const extractedString = tagSelectRules(props, selectedRuleRef.current);

            if (extractedString) {
                console.log('Extracted string:', extractedString);
                setSelectedTagIdViewerLeft(extractedString);
            } else {
                console.warn('tagSelectRules did not return a valid string. Result:', extractedString);
            }
        }, (error) => {
            console.error('Error getting properties:', error);
        });
    };
    const loadDocument = (Autodesk, urn) => {
        Autodesk.Viewing.Document.load(
            `urn:${urn}`,
            (doc) => {
                const viewable = doc.getRoot().getDefaultGeometry();
                viewerRight.current.loadDocumentNode(doc, viewable).then(() => {
                    // to get not get only shapes and cylinders
                    viewerRight.current.setSelectionMode(Autodesk.Viewing.SelectionMode.LAST_OBJECT)
                    viewerRight.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function () {
                        console.log('Geometry loaded event fired.');
                        setViewerLoaded(true);
                        loadCustomExtensions();
                        performSearchIsolateAndFit(selectedTagIdViewerRight);
                    });
                });
            },
            (errorCode) => {
                console.error('Error loading document:', errorCode);
            }
        );
    };

    const resizeViewer = useCallback(() => {
        if (viewerRight.current) {
            viewerRight.current.resize();
        }
    }, []);



    const closeViewer = () => {
        setInitialized(false);
        const {Autodesk} = window;

        if (viewerRight.current) {
            viewerRight.current.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT);
            viewerRight.current.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT);
            viewerRight.current.finish();
            viewerRight.current = null;
            // setInitialized(false);
        }
    };

    const openViewer = () => {
        setInitialized(true);
    };

    const handleSelectOptions = (event) => {
        const value = event.target.value;

        setSelectedOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((mode) => mode !== value)
                : [...prevSelected, value]
        );

        setActions((prevActions) => ({
            ...prevActions,
            [value]: !prevActions[value]
        }));
    };
    useEffect(() => {
        console.log('Actions state updated viewer right:', actions);
    }, [actions]);

    useEffect(() => {
        console.log("viewer loaded",viewerLoaded)
        if (!viewerLoaded || !viewerRight.current) {
            console.warn('Viewer right not fully loaded yet.');
            return;
        }

        if (selectedOptionsRef.current.includes('selectionColourRed')) {
            viewerRight.current.setSelectionColor((0xff0000), window.Autodesk.Viewing.SelectionType.MIXED);
            viewerRight.current.set2dSelectionColor((0xff0000), 1);
            console.log("selection color set to red");
        } else {
            viewerRight.current.setSelectionColor((0x82B4FA), window.Autodesk.Viewing.SelectionType.MIXED);
            viewerRight.current.set2dSelectionColor((0x82B4FA), 1);
            console.log("selection color back to standard")
        }
    }, [selectedOptionsRef.current, viewerLoaded, actions]);
// load extensions after model is loaded
    function loadCustomExtensions() {
        const extension1 = "Autodesk.Viewing.ZoomWindow";
        const  options1 = []
        viewerRight.current.loadExtension(extension1).then(function(extensionInstance) {
            console.log('Extension loaded:', extensionInstance);
            // viewerLeft.current.activateExtension(extension, options);
        }).catch(function(error) {
            console.error('Error loading extensions:', error);
        });
    }



    return (
        // set the width only if the viewer is open
        <div style={{width: '100%', height: initialized ? '400px' : '100%'}}>
            <div ref={viewerDivRight} id="viewerDivRight" style={{width: '100%', height: '100%'}}></div>
            <div className="viewerButtonsRight">
                {!initialized &&
                    <button className="btn btn-secondary w-auto" onClick={openViewer}>Open right viewer</button>}
                {initialized &&
                    <button className="btn btn-secondary w-auto" onClick={closeViewer}>Close right viewer</button>}
                <select id="rulesDropdown" value={selectedRule} onChange={handleRuleChange}>
                    {rules.map((rule) => (
                        <option key={rule.value} value={rule.value}>
                            {rule.label}
                        </option>
                    ))}
                </select>
                <select
                    id="viewerLinkModeDropdown"
                    // className="form-select"
                    value=""
                    onChange={handleSelectOptions}
                    style={{height: 'auto'}}
                >
                    {viewerLinkModes.map((mode) => (
                        <option
                            key={mode.value}
                            value={mode.value}
                            style={{
                                backgroundColor: selectedOptions.includes(mode.value) ? 'green' : 'white',
                                color: selectedOptions.includes(mode.value) ? 'white' : 'black'
                            }}
                        >
                            {mode.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default ViewerRight;
