export const AirtableService = async (column, value) => {
    const url = `${process.env.REACT_APP_API_URL}/fetchRow?column=${encodeURIComponent(column)}&value=${encodeURIComponent(value)}`;

    const request = new Request(url, {
        method: 'GET',
        credentials: 'include', // Ensure cookies are sent with the request if needed
    });

    try {
        const response = await fetch(request);
        console.log('Response fetchRow:', response);

        if (!response.ok) {
            throw new Error(`HTTP error from fetching row! Status: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched row data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching row data:', error);
        throw error;
    }
};
