export const getLastPartUsingRegex = (inputString, pattern) => {
    const regex = new RegExp(pattern, 'g');
    const matches = [...inputString.matchAll(regex)];
    if (matches.length === 0) {
        return inputString;
    }
    const lastMatch = matches[matches.length - 1];
    return inputString.substring(lastMatch.index + 1).trim();
};

export const tagSelectRules = (props, selectedRule) => {
    if (!selectedRule) return '';
// debugger;
    switch (selectedRule) {
        case 'navisworks':
            console.log('Properties of the selected object tagSelectRules:', props);
            return getLastPartUsingRegex(props.name || '', '[\\/\\\\]');
            // return props.name;
        case 'plant3d':
            if (props.properties[4].displayValue.includes(';')) {
                console.log('Properties of the selected object tagSelectRules:', props);
                const tempResult = getLastPartUsingRegex(props.properties[4].displayValue || '', ';([^;\\\\]+)\\\\');
                console.log('Temp result:', tempResult);
                // Step 2: Split the captured part by the backslash and return the first part
                const result = tempResult.match(/^[^\\]+/);
                if (result) {
                    console.log('Result:', result[0]);
                    return result[0];
                }
            } else {
                return props.properties[4].displayValue;
            }
        case 'test':
            console.log('Properties of the selected object tagSelectRules:', props);
            return getLastPartUsingRegex(props.name || '', '[\\/\\\\]');
        // return props.name;
    }
};

export const rules = [
    { label: 'Select selection rule', value: '' },
    { label: 'Navisworks', value: 'navisworks' },
    { label: 'Plant3d pid', value: 'plant3d' },
    // { label: 'Test', value: 'test' },
    // Add more rules here if needed
];

export const viewerLinkModes = [
    { label: 'Select options', value: '' },
    { label: 'Link viewer', value: 'link2RightAndAsset' },
    { label: 'Isolate selection', value: 'isolateSelection' },
    { label: 'Set selection colour red', value: 'selectionColourRed' },
];