// Login.js
import React from 'react';
import { AuthProvider, useAuth } from './AuthContext';


const LoginNew = () => {
    const { user, error, handleLogout, handleLogin, getAccessToken } = useAuth();
    // Save the current URL in a cookie before redirecting to the login page
    document.cookie = `originalUrl=${encodeURIComponent(window.location.href)}; path=/`;

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{ visibility: 'visible' }}>
            {user ? (
                <>
                    <button className="btn btn-secondary" onClick={handleLogout}>Logout ({user.name})</button>
                    {/*<button onClick={getAccessToken}>Get New Access Token</button>*/}
                </>
            ) : (
                <button className="btn btn-secondary" onClick={handleLogin}>Login</button>
            )}
        </div>
    );
};

export default LoginNew;