export const FetchAssetRelationShips = async ({ selectedProjectId, selectedAssetId,accessToken  }) => {
    // Fetch the access token from local storage
    // const apsToken = localStorage.getItem('access_token');
    // console.log('Fetching access token for relations:', accessToken);
    const relationShipsDomain = '?domain=autodesk-bim360-asset';
    const relationShipsType = '&type=asset';
    const relationShipsId = `&id=${selectedAssetId}`;


    // Log the project ID parameter


    // Function to remove 'b.' prefix from the project ID
    function removeBPrefix(str) {
        return str.replace(/^b\./, '');
    }

    // Remove 'b.' prefix from the project ID parameter
    const projectIdParamNoB = removeBPrefix(selectedProjectId);
    // console.log('Fetching asset projectId without b:', selectedProjectId);
    // console.log('Fetching asset with id:', selectedAssetId);

    // Prepare the request headers
    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    // Prepare the request object
    const request = new Request(`https://developer.api.autodesk.com/bim360/relationship/v2/containers/${projectIdParamNoB}/relationships:search${relationShipsDomain}${relationShipsType}${relationShipsId}`, {
        method: 'GET',
        headers: requestHeaders
    });
    // console.log('request relationships:', request);
    try {
        // Make the fetch request
        const response = await fetch(request);

        // Check if the response is okay
        if (!response.ok) {
            throw new Error(`HTTP error from relationships! Status: ${response.status} ${response.statusText}`);
        }

        // Parse and return the JSON response
        return await response.json();
    } catch (error) {
        // Log and rethrow any errors encountered
        console.error('Error fetching relationships data:', error);
        throw error;
    }
};
