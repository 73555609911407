// FetchHubs.js

export const FetchHubs = async () => {
    const request = new Request(`${process.env.REACT_APP_API_URL}/api/hubs`, {
        method: 'GET',
        credentials: 'include', // Ensure cookies are sent with the request
    });

    try {
        const response = await fetch(request);
        // console.log('Response hubs:', response);

        if (!response.ok) {
            throw new Error(`HTTP error from fetching hubs! Status: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched hubs data:', data);

        // Extract and return only the hub IDs
        const hubIds = data.map(hub => hub.id);
        console.log('Extracted hub IDs:', hubIds);
        return hubIds;
    } catch (error) {
        console.error('Error fetching hubs data:', error);
        throw error;
    }
};

