import React, {useState, useRef} from 'react';
import {FetchAssetRelationShips} from '../api/assetRelationShipsService';
import {docsMetaDataService} from '../api/docsMetaDataService';
import {docsCustomAttributesService} from '../api/docsCustomAttributesService';
import {useGlobalProvider} from "./GlobalProvider";
import {ReactTabulator} from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // default theme
import 'react-tabulator/css/tabulator_bootstrap5.min.css'; // use Theme(s)
import {useAuth} from './AuthContext';

const AssetRelationShipsTable = ({setSelectedLeftUrn, setSelectedRightUrn}) => {
    const [documentData, setDocumentData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const {selectedProjectId, selectedAssetId} = useGlobalProvider();
    const tableRef = useRef(null);
    const {getAccessToken} = useAuth();
// TODO should nto get docs before asset is selected or to many
    const fetchData = async () => {





        try {
            setLoading(true);
            setError(null);
            const accessToken = await getAccessToken();
            const data = await FetchAssetRelationShips({selectedProjectId, selectedAssetId, accessToken});
            const filteredIds = extractDocumentLineageIds(data.relationships);
            const documentMetaData = await fetchDocumentMetadata(selectedProjectId, filteredIds, accessToken);
            console.log('documentMetaData:', documentMetaData);
            const customAttributes = await fetchCustomAttributes(selectedProjectId, filteredIds, accessToken);
            console.log('customAttributes:', customAttributes);

            if (documentMetaData && customAttributes) {
                const mergedData = mergeResults(documentMetaData, customAttributes);
                setDocumentData(mergedData);
            } else {
                setDocumentData([]);
            }
            setDataFetched(true); // Mark data as fetched
        } catch (err) {
            setError('No documents for selected asset available');
        } finally {
            setLoading(false);
        }
    };

    const extractDocumentLineageIds = (relationships) => {
        console.log('relationships:', relationships);
        const ids = [];
        relationships.forEach((relationship) => {
            if (!relationship.isDeleted) {
                relationship.entities.forEach((entity) => {
                    if (entity.domain === 'autodesk-bim360-documentmanagement' && entity.type === 'documentlineage') {
                        ids.push(entity.id);
                    }
                });
            }
        });
        return ids;
    };

    const fetchDocumentMetadata = async (selectedProjectId, documentIds, accessToken) => {
        const requests = documentIds.map(id => docsMetaDataService({selectedProjectId, documentIds: id, accessToken}));
        const results = await Promise.all(requests);

// need to exclude documents still listed by  relationships returned by fetchDocumentMetadata with data.attributes.hidden == true
        const filteredResults = results.filter(result => !result.data.attributes.hidden);
        return filteredResults;
    };


    const fetchCustomAttributes = async (selectedProjectId, documentIds, accessToken) => {
        const urns = documentIds.map(id => `${id}`);
        const requestPayload = {urns};
        return docsCustomAttributesService({selectedProjectId, requestPayload, accessToken});
    };

    const mergeResults = (documentMetaData, customAttributes) => {
        return documentMetaData.map(result => {
            const additionalInfo = customAttributes.find(item => item.itemUrn === result.data.id);

            const getCustomAttributeByName = (customAttributes, attributeName) => {
                const customAttribute = customAttributes.find(attribute => attribute.name === attributeName);
                return customAttribute ? customAttribute.value : "";
            };

            return {
                itemUrn: additionalInfo.urn,
                description: result.data.attributes.extension.data.description,
                displayName: result.data.attributes.displayName,
                webView: result.data.links.webView.href,
                reference3D: getCustomAttributeByName(additionalInfo.customAttributes, "3 D reference"),
                reference2D: getCustomAttributeByName(additionalInfo.customAttributes, "2 D reference")
            };
        });
    };

    const rowMenu = [
        {
            label: "Show in left viewer",
            action: (e, row) => {
                e.preventDefault();
                console.log('urn left viewer:', row.getData().itemUrn);
                setSelectedLeftUrn(row.getData().itemUrn);
            }
        },
        {
            label: "Show in right viewer",
            action: (e, row) => {
                e.preventDefault();
                console.log('urn right viewer:', row.getData().itemUrn);
                setSelectedRightUrn(row.getData().itemUrn);
            }
        }
    ];

    const columns = [
        {
            title: "Display Name", field: "displayName", formatter: (cell) => {
                const webView = cell.getRow().getData().webView;
                const displayName = cell.getValue();
                return `<a href="${webView}" target="_blank">${displayName}</a>`;
            }
        },
        {title: "Description", field: "description"},
        {title: "3D Reference", field: "reference3D"},
        {title: "2D Reference", field: "reference2D"},
    ];

    const options = {
        layout: 'fitColumns',
        paginationSize: 200,
        pagination: false, //enable pagination
        movableColumns: true,
        resizableRows: true,
        rowContextMenu: rowMenu,
        selectable: false, // Disable row selection
    };
//check if asset is selected
    if (!selectedAssetId) {
        // console.log("No asset selected");
        return <div>No asset selected</div>;
    }
    return (
        <div>
            <div className="getDocumentsButton">
                <button className="btn btn-secondary" onClick={fetchData}>Get documents</button>
            </div>
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            {!loading && !error && dataFetched && (
                <ReactTabulator
                    data={documentData}
                    columns={columns}
                    layout="fitData"
                    selectable={true}
                    ref={tableRef}
                    options={options}
                />
            )}
        </div>
    );
};

export default AssetRelationShipsTable;
