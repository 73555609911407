// Description: This file contains the API service for patching  assets from the Autodesk Construction Cloud API.
export const PatchAssets = async ({selectedProjectId, assets, accessToken}) => {
    // const apsToken = process.env.REACT_APP_APS_TOKEN;
    const apsToken = localStorage.getItem('access_token');
    // console.log('Patching assets projectIdParam:', selectedProjectId);
    //
    // console.log('Patching assets body:', assets);
    console.log('Patching assets token', accessToken);


    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });


    const request = new Request(`https://developer.api.autodesk.com/bim360/assets/v2/projects/${selectedProjectId}/assets:batch-patch`, {
        method: 'PATCH',
        headers: requestHeaders,
        body: JSON.stringify(assets)
        // body: assets
    });
    // console.log('Request:', request);
    // // Log the request headers
    // console.log('Request Headers:', Array.from(request.headers.entries()));

    try {
        const response = await fetch(request);


        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error patching data service:', error);
        throw error;
    }
};
