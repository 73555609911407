export const docsMetaDataService = async ({ selectedProjectId, documentIds, accessToken  }) => {
    // Fetch the access token from local storage
    // const apsToken = localStorage.getItem('access_token');
    // console.log('Fetching access token for getting meta data fro docs:', accessToken);

    // Function to remove 'b.' prefix from the project ID
    // function removeBPrefix(str) {
    //     return str.replace(/^b\./, '');
    // }
    // const projectIdParamNoB = removeBPrefix(selectedProjectId);


    // Prepare the request headers
    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    // Prepare the request object
    const request = new Request(`https://developer.api.autodesk.com/data/v1/projects/${selectedProjectId}/items/${documentIds}`, {
        method: 'GET',
        headers: requestHeaders
    });
    try {
        // Make the fetch request
        const response = await fetch(request);

        // Check if the response is okay
        if (!response.ok) {
            throw new Error(`HTTP error from relationships! Status: ${response.status} ${response.statusText}`);
        }

        // Parse and return the JSON response
        return await response.json();
    } catch (error) {
        // Log and rethrow any errors encountered
        console.error('Error fetching relationships meta data:', error);
        throw error;
    }
};
