import React, {useEffect, useRef, useCallback, useState} from 'react';
import {useAuth} from './AuthContext';
import {useGlobalProvider} from "./GlobalProvider";
import {rules} from '../utilities/viewerUtils';
import {tagSelectRules} from '../utilities/viewerUtils';
import {viewerLinkModes} from '../utilities/viewerUtils';


const ViewerLeft = ({urn}) => {
    const viewerDivLeft = useRef(null);
    const viewerLeft = useRef(null);
    const {getAccessToken} = useAuth();
    const [initialized, setInitialized] = useState(false);
    const {selectedTagIdViewerLeft, setSelectedTagIdViewerRight} = useGlobalProvider();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const selectedOptionsRef = useRef(selectedOptions);
    const [viewerLoaded, setViewerLoaded] = useState(false);
    const [actions, setActions] = useState({
        // TODO rename to link2OtherViewerAndAsset
        link2RightAndAsset: false,
        isolateSelection: false,
        selectionColourRed: false
    });


    const [selectedRule, setSelectedRule] = useState('');
    // make sure the selected rule is current
    const selectedRuleRef = useRef(selectedRule);
    const [isProgrammaticIsolation, setIsProgrammaticIsolation] = useState(false);
    const isProgrammaticIsolationRef= useRef(isProgrammaticIsolation);


    // for if asset is selected or in the right viewer

    useEffect(() => {
        if (viewerLeft.current && (selectedOptionsRef.current.includes('link2RightAndAsset')) && selectedTagIdViewerLeft ) {
            console.log('Selected Tag left viewer:', selectedTagIdViewerLeft);
            performSearchIsolateAndFit(selectedTagIdViewerLeft);
        }
    }, [selectedTagIdViewerLeft]);


    // Sync isProgrammaticIsolationRef with isProgrammaticIsolation
    useEffect(() => {
        isProgrammaticIsolationRef.current = isProgrammaticIsolation;
    }, [isProgrammaticIsolation]);


    useEffect(() => {
        selectedOptionsRef.current = selectedOptions;
    }, [selectedOptions]);


    useEffect(() => {
        if (!initialized || !viewerDivLeft.current) return;

        const {Autodesk} = window;
        initializeViewer(Autodesk);

        const resizeObserver = new ResizeObserver(() => {
            resizeViewer();
        });

        resizeObserver.observe(viewerDivLeft.current);

        return () => {
            if (viewerLeft.current) {
                viewerLeft.current.finish();
                viewerLeft.current = null;
            }
            resizeObserver.disconnect();
        };
    }, [initialized]);

    useEffect(() => {
        if (viewerLeft.current && urn) {
            const {Autodesk} = window;
            loadDocument(Autodesk, urn);
        }
    }, [urn, initialized]);


    const initializeViewer = (Autodesk) => {
        const options = {
            env: 'AutodeskProduction',
            getAccessToken: async (onTokenReady) => {
                try {
                    const token = await getAccessToken();
                    const timeInSeconds = 3600;
                    onTokenReady(token, timeInSeconds);
                } catch (error) {
                    console.error('Error fetching access token:', error);
                }
            },
        };
//'ViewCubeUi', 'Measure', 'Autodesk.BIM360.Minimap', 'Autodesk.Viewing.ZoomWindow', 'DefaultTools.NavTools',Autodesk.ADN.Viewing.Extension.ScreenShotManage
        Autodesk.Viewing.Initializer(options, () => {
            const config = {
                // don't load extensions here to early.
                // extensions: ['Autodesk.Viewing.ZoomWindow'],
            };
            viewerLeft.current = new Autodesk.Viewing.GuiViewer3D(viewerDivLeft.current, config);
            viewerLeft.current.start();

            loadDocument(Autodesk, urn);
// for tthe selection in the object tree
            viewerLeft.current.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, function (event) {
                console.log('Selection changed event isProgrammaticIsolation:', isProgrammaticIsolationRef.current);

                const dbIds =viewerLeft.current.getIsolatedNodes();
                console.log('isolated nodes', dbIds);
                if (!isProgrammaticIsolationRef.current && dbIds > 0) {

                    processDbId(viewerLeft.current, dbIds);
                }
            });



            viewerLeft.current.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, function (event) {
                console.log('Selection changed event isProgrammaticIsolation:', isProgrammaticIsolationRef.current);
                if (!isProgrammaticIsolationRef.current && event.dbIdArray.length > 0) {

                    processDbId(viewerLeft.current, event.dbIdArray[0]);
                }
            });
        });
    };
    const performSearchIsolateAndFit = (tagId) => {
        if (!viewerLeft.current) return;
// debugger;
        setIsProgrammaticIsolation( true);
        console.log('Performing search isolate and fit isProgrammaticIsolationRef :', !isProgrammaticIsolationRef.current);


        viewerLeft.current.search(tagId, (ids) => {

            if (selectedOptions.includes('isolateSelection') && !viewerLeft.current.getIsolatedNodes()) {
                viewerLeft.current.isolate(ids);
            }
            // important for the selection colour
            viewerLeft.current.select(ids);



            if (ids.length > 0) {
                viewerLeft.current.fitToView(ids);
                // let is3D = viewerLeft.current.model.is3d();
                // let camera = viewerLeft.current.getCamera();
                // let originalFov = camera.fov;
                // if (!is3D) {
                //     // this is a 2D model
                //     // camera.position.z -= 10  // Adjust this value to achieve the desired level of "zoom out"
                //     // viewer.applyCamera(camera, true);
                //     //camera.zoom = 2;
                //     // viewerLeft.current.set2dSelectionColor(new THREE.Color(200,200,0),1); // Blue for 2D selection
                //
                //     camera.fov *= 0.8;
                //     viewerLeft.current.impl.invalidate(true);
                // }
                // if (is3D) {
                //     // this is a 3D model
                //     // camera.position.z += 30  // Adjust this value to achieve the desired level of "zoom out"
                //     // viewerLeft.current.applyCamera(camera, true);
                //     //Todo be careful with isolating as it blocks getting the tag id for some reason
                //     // viewerLeft.current.isolate(ids);
                //     // camera.fov *= 200;
                //     // viewerLeft.current.impl.invalidate(true);
                // }
                // // viewerLeft.current.fitToView(ids)
                // // // When done, restore original fov
                //
                // camera.fov = originalFov;
                // viewerLeft.current.applyCamera(camera);
                // // Update the viewer again
                // viewerLeft.current.impl.invalidate(true);

            }


            setIsProgrammaticIsolation(false);
            //TODO needs to be here to avoid endless loop with SELECTION_CHANGED_EVENT
            // viewerLeft.current.select(ids);
        });
    };

    const handleRuleChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedRule(selectedValue);
        // console.log('Selected rule handleRuleChange :', selectedValue,selectedRule);
    };
    useEffect(() => {
        // console.log('Selected rule updated useeffect:', selectedRule);
        selectedRuleRef.current = selectedRule;
    }, [selectedRule]); // This effect runs every time selectedRule changes

// TODO should called if click on object
    const processDbId = (viewer, dbId) => {
        console.log('Selected isProgrammaticIsolation:', isProgrammaticIsolation);
        viewer.getProperties(dbId, (props) => {
            console.log('Properties of the selected object processDbId viewer left:', props);
            // without useref I have to rerender
            // console.log('Selected options:', selectedOptionsRef.current);
            if (selectedOptionsRef.current.includes('isolateSelection')) {
                viewerLeft.current.isolate(dbId);
            }

            // Add logging inside the tagSelectRules function to debug its behavior
            const extractedString = tagSelectRules(props, selectedRuleRef.current);

            if (extractedString) {
                console.log('Extracted string:', extractedString);
                setSelectedTagIdViewerRight(extractedString);
                // setSelectedTagId(selectedTagIdViewerLeft);
            } else {
                console.warn('tagSelectRules did not return a valid string. Result:', extractedString);
            }
        }, (error) => {
            console.error('Error getting properties:', error);
        });
    };


    const loadDocument = (Autodesk, urn) => {
        Autodesk.Viewing.Document.load(
            `urn:${urn}`,
            (doc) => {
                const viewable = doc.getRoot().getDefaultGeometry();
                viewerLeft.current.loadDocumentNode(doc, viewable).then(() => {
                    // to get not get only shapes and cylinders
                    viewerLeft.current.setSelectionMode(Autodesk.Viewing.SelectionMode.LAST_OBJECT)

                    viewerLeft.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function () {
                        setViewerLoaded(true);
                        loadCustomExtensions();
                        performSearchIsolateAndFit(selectedTagIdViewerLeft);
                    });


                });
            },
            (errorCode) => {
                console.error('Error loading document:', errorCode);
            }
        );
    };

    const resizeViewer = useCallback(() => {
        if (viewerLeft.current) {
            viewerLeft.current.resize();
        }
    }, []);


    const closeViewer = () => {
        setInitialized(false);
        const {Autodesk} = window;
        if (viewerLeft.current) {
            // TODO check if the remove event listener is needed
            viewerLeft.current.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT);
            viewerLeft.current.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT);
            viewerLeft.current.finish();
            viewerLeft.current = null;
            // setInitialized(false);
        }
    };

    const openViewer = () => {
        setInitialized(true);
    };
//
    const handleSelectOptions = (event) => {
        const value = event.target.value;

        setSelectedOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((mode) => mode !== value)
                : [...prevSelected, value]
        );

        setActions((prevActions) => ({
            ...prevActions,
            [value]: !prevActions[value]
        }));
    };
    useEffect(() => {
        console.log('Actions state updated viewer left:', actions);
    }, [actions]);

    useEffect(() => {
        if (!viewerLoaded || !viewerLeft.current) {
            console.warn('Viewer left not fully loaded yet.');
            return;
        }

        if (selectedOptionsRef.current.includes('selectionColourRed')) {
            viewerLeft.current.setSelectionColor((0xff0000), window.Autodesk.Viewing.SelectionType.MIXED);
            viewerLeft.current.set2dSelectionColor((0xff0000), 1);
            console.log("selection color set to red");
        } else {
            viewerLeft.current.setSelectionColor((0x82B4FA), window.Autodesk.Viewing.SelectionType.MIXED);
            viewerLeft.current.set2dSelectionColor((0x82B4FA), 1);
            console.log("selection color back to standard")
        }
    }, [selectedOptionsRef.current, viewerLoaded, actions]);
// load extensions after model is loaded
    function loadCustomExtensions() {
        const extension1 = "Autodesk.Viewing.ZoomWindow";
        const  options1 = []
        viewerLeft.current.loadExtension(extension1).then(function(extensionInstance) {
            console.log('Extension loaded:', extensionInstance);
            // viewerLeft.current.activateExtension(extension, options);
        }).catch(function(error) {
            console.error('Error loading extensions:', error);
        });
    }

    return (
        // set the width only if the viewer is open
        <div style={{width: '100%', height: initialized ? '400px' : '100%'}}>
            <div ref={viewerDivLeft} id="viewerDivLeft" style={{width: '100%', height: '100%'}}></div>
            <div className="viewerButtonsLeft">
                {!initialized &&
                    <button className="btn btn-secondary w-auto" onClick={openViewer}>Open left viewer</button>}
                {initialized &&
                    <button className="btn btn-secondary w-auto" onClick={closeViewer}>Close left viewer</button>}
                <select id="rulesDropdown" value={selectedRule} onChange={handleRuleChange}>
                    {rules.map((rule) => (
                        <option key={rule.value} value={rule.value}>
                            {rule.label}
                        </option>
                    ))}
                </select>
                <select
                    id="viewerLinkModeDropdown"
                    // className="form-select"
                    value=""
                    onChange={handleSelectOptions}
                    style={{height: 'auto'}}
                >
                    {viewerLinkModes.map((mode) => (
                        <option
                            key={mode.value}
                            value={mode.value}
                            style={{
                                backgroundColor: selectedOptions.includes(mode.value) ? 'green' : 'white',
                                color: selectedOptions.includes(mode.value) ? 'white' : 'black'
                            }}
                        >
                            {mode.label}
                        </option>
                    ))}
                </select>
                {/*<button onClick={loadCustomExtension}>Test</button>*/}
            </div>
        </div>
    );
};

export default ViewerLeft;
