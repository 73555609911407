// Description: This file contains the API service for fetching assets from the Autodesk Construction Cloud API.
export const FetchAssets = async ({selectedProjectId, selectedCategoryId,accessToken}, assetFetchOptions) => {
    // console.log('Fetching assets projectIdParam:', selectedProjectId);
    console.log('assetFetchOptions', assetFetchOptions)
    selectedCategoryId = selectedCategoryId == null ? "1" : selectedCategoryId;


    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    // Create a Request object to inspect headers
    // TODO pagination in table component
    // TODO search: 	https://developer.api.autodesk.com/construction/assets/v2/projects/b.064ae33d-b0c8-4932-9e8a-3a16f8fb53e7/assets?filter[searchText]=E1301&includeCustomAttributes=true&includeDeleted=true&limit=200
    const request = new Request(`https://developer.api.autodesk.com/construction/assets/v2/projects/${selectedProjectId}/assets/${assetFetchOptions}`, {
        method: 'GET',
        headers: requestHeaders
    });

    // Log the request headers
    // console.log('Request Headers:', Array.from(request.headers.entries()));

    try {
        const response = await fetch(request);


        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data service:', error);
        throw error;
    }
};
