// ProjectsList.js
import React, { useState, useEffect } from 'react';
import { FetchProjects } from '../api/projectsService';
import { useGlobalProvider } from './GlobalProvider';
import { useAuth } from './AuthContext';

const ProjectsList = () => {
    const [items, setItems] = useState([]);
    const { selectedProjectId, loginStatus, setSelectedProjectId, setSelectedCategoryId } = useGlobalProvider();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const { getAccessToken } = useAuth();


    // useEffect to watch for changes in selectedProjectId
    useEffect(() => {
        setSelectedCategoryId(1);
    }, [selectedProjectId, setSelectedCategoryId]);

    // Function to fetch projects
    const fetchProjects = async () => {
        setLoading(true);
        try {
            const accessToken = await getAccessToken();
            // console.log('Token in project list:', token);
            const projects = await FetchProjects(accessToken);
            // response is on the data object
            if (projects.data && projects.data.length > 0) {
                setItems(projects.data);
            } else {
                console.log("No projects returned from API", projects);
            }
        } catch (err) {
            console.error("API Error:", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // useEffect to fetch projects when loginStatus is true
    useEffect(() => {
        if (loginStatus) {
            fetchProjects();
        }
    }, [loginStatus, getAccessToken]);

    const handleSelectProject = (event) => {
        setSelectedProjectId(event.target.value);
    };

    // if (loading) {
    //     return <div>Loading projects...</div>;
    // }

    if (error) {
        return <div>Error loading data: {error}</div>;
    }

    return (
        <div>
            {loginStatus && (
                <select className="form-select w-auto" value={selectedProjectId || ""} onChange={handleSelectProject}>
                    <option value="" disabled>Select Project</option>
                    {items.map(project => (
                        <option key={project.id} value={project.id}>
                            {project.attributes.name}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default ProjectsList;
