import React, {createContext, useContext, useState, useEffect} from 'react';
import {AuthProvider, useAuth} from './AuthContext';

const GlobalContext = createContext({
    selectedCategoryId: null,
    setSelectedCategoryId: () => {
    },
    selectedProjectId: null,
    setSelectedProjectId: () => {
    },
    selectedTagId: null,
    setSelectedTagId: () => {
    },
    selectedTagIdViewerLeft: null,
    setSelectedTagIdViewerLeft: () => {
    },
    selectedTagIdViewerRight: null,
    setSelectedTagIdViewerRight: () => {
    },
    selectedAssetId: null,
    setSelectedAssetId: () => {
    },
    loginStatus: null,
    setLoginStatus: () => {
    },
});

export const useGlobalProvider = () => useContext(GlobalContext);

export const GlobalProvider = ({children}) => {
    const [selectedCategoryId, setSelectedCategoryId] = useState('1');
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [selectedAssetId, setSelectedAssetId] = useState('');
    const [selectedTagId, setSelectedTagId] = useState('');
    const [selectedTagIdViewerLeft, setSelectedTagIdViewerLeft] = useState('');
    const [selectedTagIdViewerRight, setSelectedTagIdViewerRight] = useState('');
    const [loginStatus, setLoginStatus] = useState('');


    // Log when selectedCategoryId changes
    useEffect(() => {
        console.log("Selected Category ID changed to:", selectedCategoryId);
    }, [selectedCategoryId]);

    // Log when selectedProjectId changes
    useEffect(() => {
        console.log("Project ID changed to:", selectedProjectId);
    }, [selectedProjectId]);

    // Log when selectedAssetId changes
    useEffect(() => {
        console.log("Asset ID changed to:", selectedAssetId);
    }, [selectedAssetId]);

    useEffect(() => {
        console.log("Login status:", loginStatus);
    }, [loginStatus]);

// TODO selector for viewer assets linking modes may be in viewer
//     useEffect(() => {
//         console.log("selected TAG in GlobalProvider setting viewers:", selectedTagId);
//         setSelectedTagIdViewerLeft(selectedTagId);
//         setSelectedTagIdViewerRight(selectedTagId);
//     }, [selectedTagId]);
// update input field in asset table
    useEffect(() => {
        console.log("selected TAG id Viewer 1 in GlobalProvider:", selectedTagIdViewerLeft);
        setSelectedTagId(selectedTagIdViewerLeft);
    }, [selectedTagIdViewerLeft]);

    useEffect(() => {
        console.log("selected TAG id Viewer 2 in GlobalProvider:", selectedTagIdViewerRight);
        setSelectedTagId(selectedTagIdViewerRight);
    }, [selectedTagIdViewerRight]);

    const value = {
        selectedCategoryId,
        setSelectedCategoryId,
        selectedProjectId,
        setSelectedProjectId,
        selectedAssetId,
        setSelectedAssetId,
        selectedTagId,
        setSelectedTagId,
        selectedTagIdViewerLeft,
        setSelectedTagIdViewerLeft,
        selectedTagIdViewerRight,
        setSelectedTagIdViewerRight,
        loginStatus,
        setLoginStatus,
    };

    return (
        <GlobalContext.Provider value={value}>
            <AuthProvider>
                {children}
            </AuthProvider>
        </GlobalContext.Provider>
    );
};
