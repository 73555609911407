export const docsCustomAttributesService = async ({ selectedProjectId, requestPayload, accessToken }) => {
    // const apsToken = localStorage.getItem('access_token');
    // console.log('Fetching document custom attributes for project:', selectedProjectId);
    // console.log('Document custom attributes URNs:', requestPayload);

    // Function to remove 'b.' prefix from the project ID
    function removeBPrefix(str) {
        return str.replace(/^b\./, '');
    }
    const projectIdParamNoB = removeBPrefix(selectedProjectId);

    // Prepare the request headers
    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });



    // Prepare the request object
    const request = new Request(`https://developer.api.autodesk.com/bim360/docs/v1/projects/${projectIdParamNoB}/versions:batch-get`, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(requestPayload)
    });

    // console.log('Request document custom attributes:', request);
    try {
        // Make the fetch request
        const response = await fetch(request);

        // Check if the response is okay
        if (!response.ok) {
            throw new Error(`HTTP error from getting document custom attributes: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        // Parse and return the JSON response
        return data.results;
    } catch (error) {
        // Log and rethrow any errors encountered
        console.error('Error fetching document custom attributes:', error);
        throw error;
    }
};
