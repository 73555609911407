export const FetchCategories = async ({selectedProjectId, accessToken}) => {


    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    const request = new Request(`https://developer.api.autodesk.com/bim360/assets/v1/projects/${selectedProjectId}/categories?filter[isActive]=true`, {
        method: 'GET',
        headers: requestHeaders
    });

    try {
        const response = await fetch(request);
        // console.log('Response categories:', response);

        if (!response.ok) {
            throw new Error(`HTTP error from fetching categories! Status: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
