export const FetchCustomProperties = async ({selectedProjectId, accessToken}) => {
    // const apsToken = process.env.REACT_APP_APS_TOKEN;
    // const apsToken = localStorage.getItem('access_token');
    // console.log('Token in FetchCustomProperties:', accessToken);
    // console.log('Fetching custom properties projectIdParam:', selectedProjectId);
    // console.log('Fetching custom properties selectedCategoryId', selectedCategoryId);
    const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    // Create a Request object to inspect headers
    const request = new Request(`https://developer.api.autodesk.com/bim360/assets/v1/projects/${selectedProjectId}/custom-attributes`, {
        method: 'GET',
        headers: requestHeaders
    });

    try {
        const response = await fetch(request);
        // console.log('Response FetchCustomProperties:', response.json);


        if (!response.ok) {
            throw new Error(`HTTP error from customprops! Status: ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
