// FetchProjects.js
// FetchProjects.js
import { FetchHubs } from './hubsService';

export const FetchProjects = async (accessToken) => {
    try {
        const hubIds = await FetchHubs();
        if (hubIds.length === 0) {
            throw new Error('No hubs found');
        }
        const accHubId = hubIds[0]; // Assuming you want to use the first hub ID

        console.log('Using accHubId:', accHubId);

        const requestHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        });

        const request = new Request(`https://developer.api.autodesk.com/project/v1/hubs/${accHubId}/projects`, {
            method: 'GET',
            headers: requestHeaders,
        });

        const response = await fetch(request);
        if (!response.ok) {
            throw new Error(`HTTP error from projects! Status: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched projects data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching projects data:', error);
        throw error;
    }
};
