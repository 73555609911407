import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <h2 className="accordion-header w-100">
                <Button class="btn accordion-button fw-bold" onClick={toggle} style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <span>{isOpen ? '-' : '+'}</span>
                    <span>{title}</span>

                </Button>
                </h2>
                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            {children}
                        </CardBody>
                    </Card>
                </Collapse>
        </div>
);
};

export default AccordionItem;
